export class Const {
    //business registration keys
    public static readonly BUSINESS_REG_KEY = 'business_registration';
    public static readonly NIC_FRONT_KEY = 'nic_front';
    public static readonly NIC_BACK_KEY = 'nic_back';
    public static readonly COMPANY_DETAILS_KEY = 'companyDetails';
    public static readonly AUTHORIZED_PERSON_KEY = 'authorizedPersonDetails';
    public static readonly ACCOUNT_DETAILS_KEY = 'accountDetails';
    public static readonly FORM_1_KEY = 'form_1';
    public static readonly FORM_12_KEY = 'form_12';
    public static readonly PAYLINK_IMAGE_KEY = 'image';
    public static readonly PAYLINK_DETAILS_KEY = 'payLinkDetails';


    //request keys
    public static readonly FILE = "file";
    public static readonly TEXT = "text";

    //status
    public static readonly ACTIVE = "ACTIVE";
    public static readonly DISABLED = "DISABLED";

    //Login user steps
    public static readonly PENDING_ADMIN_APPROVAL = "PENDING_ADMIN_APPROVAL";
    public static readonly CLEARPAY_INITIAL_APPROVED = "CLEARPAY_INITIAL_APPROVED";
    public static readonly BANK_APPROVED = "BANK_APPROVED";
    public static readonly KYC_INFO_REQUIRED = "KYC_INFO_REQUIRED";
    public static readonly KYC_COMPLETED = "KYC_COMPLETED";
    public static readonly KYC_INFO_REQUIRED_MESSAGE = "please fill kyc form";

    // alert messages
    public static readonly SOMETHING_WRONG = "Something went wrong!, Please try again later.";

    // business type select sections
    public static readonly BUSINESS_INFO = "info";
    public static readonly BUSINESS_TYPE = "type";
    public static readonly BUSINESS_BANK_ACCOUNT = "account";

    //pages
    public static readonly LOGIN = "Login";
    public static readonly REGISTER = "Register";

    //File names
    public static readonly IMAGE_FILE_NAME = "image.png";

    //Meta data
    public static readonly RECEIVER_TYPE = "RECEIVER";
    public static readonly RECEIVER_DUEDATE_TYPE = "DUE_DATE";

    public static readonly RECEIVER_NAME_KEY = "receiver_name";
    public static readonly RECEIVER_EMAIL_KEY = "receiver_email";
    public static readonly RECEIVER_CONTACT_KEY = "receiver_contact";
    public static readonly RECEIVER_DUEDATE_KEY = "due_date";



}
