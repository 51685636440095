import {Observable} from 'rxjs';

export class NetError extends Error {

    message: string;
    code: number;
    type: string;

    static UN_AUTHORISE_ERROR = 401;
    static INTERNAL_SERVER_ERROR = 500;
    static BAD_GETWAY_ERROR = 502 || 503;

    constructor(message?: string, code?: number, type?: string) {
        super(message);
        this.message = message;
        this.code = code;
        this.type = type;
    }

    static fromResponse(response):  Error {
        const n = new NetError();
        if (response && response.status) {
            n.code = response.status;
        } else {
            n.message = 'something went wrong. server response is not available';
            return n;
        }

        if (typeof response.error === 'string') {
            n.message = response.error;
        } else {
            const json = response.error;
            n.message = 'response.message not available';
            if ('message' in json) {
                n.message = json.message;
            }

            if ('type' in json) {
                n.type = json.type;
            }
        }

        return n;
    }

    static fromMsg(message: string): Error {
        return new NetError(message);
    }

}
