import {Injectable, TemplateRef} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor() {
    }

    toasts: any[] = [];

    showInfo(text: string, delay = 5000) {
        this.toasts.push({text, delay: delay, classname: 'bg-info text-light'});
    }

    showSuccess(text: string, delay = 5000) {
        this.toasts.push({text, delay: delay, classname: 'bg-success text-light'});
    }

    showError(text: string, delay = 5000, tag = null) {
        if (!this.isTagAlreadyVisible(tag)) {
            this.toasts.push({text, delay: delay, classname: 'bg-danger text-light'});
        }
    }

    remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }

    private isTagAlreadyVisible(tag) {
        if (tag == null) {
            return false;
        }
        return this.toasts.fill(t => t.tag == tag).length != 0;
    }
}
