import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Company} from '../../../models/Company';
import {FormDataFile} from '../../../models/FormDataFile';
import {Const} from '../../../commons/const';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Log} from '../../../commons/log';
import {ToastService} from '../../../services/toast/toast.service';

@Component({
    selector: 'app-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
    @Input() isFileUploaded = false;
    @Input() key: string;
    @Output() fileChange = new EventEmitter<FormDataFile>();
    formDataFile = new FormDataFile();
    files: File[] = [];
    @Input() progress = 0;

    constructor(private http: HttpClient, private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.formDataFile.key = this.key;
        this.formDataFile.type = Const.FILE;
    }

    onSelect(event) {
        if (event.rejectedFiles.length > 0) {
            this.toastService.showError('Invalid file format! Please select Image or Pdf file format');
            return;
        }
        this.files = [];
        this.files.push(...event.addedFiles);
        this.setFormDataFile(this.files[0]);

    }

    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
        this.setFormDataFile(null);
    }

    private setFormDataFile(file: any) {
        this.formDataFile.content = file;
        this.fileChange.emit(this.formDataFile);
    }

}
