import {environment} from '../../../environments/environment';

export class Endpoint {

    static SERVER = environment.apiUrl + '/api/';

    endpoint: string;

    static LOGIN = Endpoint.SERVER + 'login';
    static USER = Endpoint.SERVER + 'users';
    static GENERATE_PAYLINK = Endpoint.SERVER + 'merchant/pay-link/{id}/generate/link';
    static SEARCH_MERCHANT = Endpoint.SERVER + 'admin/merchants';
    static ACTIVE_MERCHANT = Endpoint.SERVER + 'admin/merchants/{id}/sandbox/approve';
    static PRODUCTION_ACTIVE_MERCHANT = Endpoint.SERVER + 'admin/merchants/{id}/production/approve';
    static REJECT_MERCHANT = Endpoint.SERVER + 'admin/merchants/{id}/reject-profile';
    static PROFILE = Endpoint.SERVER + 'admin/merchants/{id}/full-profile';
    static UPLOAD_INVOICE_FILE = Endpoint.SERVER + 'merchant/invoice/upload/file';
    static MERCHANT_SUMMERY = Endpoint.SERVER + 'admin/merchants/summary';
    static PROJECTS = Endpoint.SERVER + 'admin/project/{id}/merchant-id/all';
    static PACKAGES = Endpoint.SERVER + 'admin/package';
    static MERCHANT_LIST = Endpoint.SERVER + 'admin/merchant-id';
    static UPDATE_PROJECT = Endpoint.SERVER + 'admin/project/{id}/merchant-id';
    static PROJECT_PACKAGE_DETAILS = Endpoint.SERVER + 'admin/project/{id}/package-details';
    static UPDATE_PROJECT_PACKAGE_DETAILS = Endpoint.SERVER + 'admin/project/{id}/package-details';

    static SEARCH_PAYOUTS = Endpoint.SERVER + 'admin/payout';
    static SEARCH_TRANSACTION = Endpoint.SERVER + 'admin/transactions';
    static TRANSACTION = Endpoint.SERVER + 'admin/transactions/{id}';

    static DOWNLOAD_PAYOUTS = Endpoint.SERVER + 'admin/payout/requests';
    static SEARCH_REFUNDS = Endpoint.SERVER + 'admin/refund';

    static APPROVE_REFUND = Endpoint.SERVER + 'admin/refund/{id}/approve';
    static REJECT_REFUND = Endpoint.SERVER + 'admin/refund/{id}/reject';

    static APPROVE_INITIAL = Endpoint.SERVER + 'admin/merchants/{id}/initial/approve';
    static REJECT_INITIAL = Endpoint.SERVER + 'admin/merchants/{id}/initial/reject';

    static TRANSACTION_COUNT = Endpoint.SERVER + 'admin/transactions/count';
    static TRANSACTION_VOID = Endpoint.SERVER + 'admin/void/{id}';
    static TRANSACTION_SUMMERY = Endpoint.SERVER + 'admin/transactions/summary';
    static MERCHANT_COUNT = Endpoint.SERVER + 'admin/merchants/count';
    static PAYOUT_SUMMERY = Endpoint.SERVER + 'admin/payout/summary';

    static PAYOUT_REQUEST = Endpoint.SERVER + 'bank/payout/requests';
    static PAYOUT_RESULT = Endpoint.SERVER + 'bank/payout/result';
    static SEARCH_MERCHANT_BANK = Endpoint.SERVER + 'bank/merchant';

    static MERCHANT_PROFILE_BANK = Endpoint.SERVER + 'bank/merchant/{id}/full-profile';
    static APPROVE_BANK = Endpoint.SERVER + 'bank/merchant/{id}/approve';
    static REJECT_BANK = Endpoint.SERVER + 'bank/merchant/{id}/reject';

    static CHARGE_BACK = Endpoint.SERVER + 'admin/chargeback/order/{id}';
    static CHARGE_BACK_HISTORY = Endpoint.SERVER + 'admin/chargeback';
    static CHARGE_BACK_APPROVE = Endpoint.SERVER + 'admin/chargeback/{id}/approve';
    static CHARGE_BACK_REJECT = Endpoint.SERVER + 'admin/chargeback/{id}/reject';

    static SEARCH_OTHER_BANK_CHARGE = Endpoint.SERVER + 'bank/payout/other-bank/charge';
    static OTHER_BANK_CHARGE = Endpoint.SERVER + 'bank/payout/other-bank/charge/{id}';

    static SEARCH_PAYOUTS_FOR_BANK = Endpoint.SERVER + 'bank/payout';

    pathParams = {};

    get url(): string {
        let url = this.endpoint;
        Object.keys(this.pathParams).forEach(i => {
            url = url.replace('{' + i + '}', this.pathParams[i]);
        });
        return url;
    }

    static withUrl(url: string): Endpoint {
        const endpoint = new Endpoint();
        endpoint.endpoint = url;
        return endpoint;
    }

    static withPathParam(url: string, pathParams = {}): Endpoint {
        const endpoint = new Endpoint();
        endpoint.endpoint = url;
        endpoint.pathParams = pathParams;
        return endpoint;
    }
}
