import {Component, Input, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {UserModel} from '../../models/UserModel';
import {Log} from '../../commons/log';
import {Const} from '../../commons/const';
import {UserService} from '../../services/user-service/user.service';
import {SessionService} from '../../services/session/session.service';
import {Router} from '@angular/router';
import {MaintenacneService} from "../../services/maintenance/maintenacne.service";

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

    faBars = faBars;
    @Input() username = 'Example Name';
    @Input() user: UserModel;
    shouldUserIconShow = false;
    showMaintenance = null;

    constructor(public userService: UserService,
                private sessionService: SessionService,
                private maintenanceService: MaintenacneService,
                private router: Router) {

    }

    ngOnInit(): void {
        if (this.user.step != Const.KYC_INFO_REQUIRED) {
            this.shouldUserIconShow = true;
        }

        if ($('.page-wrapper').hasClass('toggled')) {
            $('#show-sidebar').attr('style', 'display: flex !important');
        } else if (!$('.page-wrapper').hasClass('toggled')) {
            $('#close-sidebar').attr('style', 'display: none !important');
        }

        $('#close-sidebar').click(function () {
            $('.page-wrapper').removeClass('toggled');

            $('#close-sidebar').attr('style', 'display: none !important');
            $('#show-sidebar').attr('style', 'display: flex !important');
        });
        $('#show-sidebar').click(function () {
            $('.page-wrapper').addClass('toggled');

            $('#close-sidebar').attr('style', 'display: flex !important');
            $('#show-sidebar').attr('style', 'display: none !important');

        });
        this.checkIsMaintenance();
    }

    checkIsMaintenance() {

        this.maintenanceService.dataTransferObservable.subscribe(str => {
            this.showMaintenance = str;
        }, error => {
            console.log(error);
        });
    }

    logout() {
        this.sessionService.clearUserData();
        this.router.navigateByUrl('/login');
    }

    toProfile() {
        this.router.navigateByUrl('/merchants/dashboard/merchant-profile');
    }
}
