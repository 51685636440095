import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'UtcLocal'
})
export class UtcLocal implements PipeTransform {


    transform(value: string, ...args: unknown[]): unknown {
        if (value == null || value == '' || value == undefined) {
            return '';
        }
        let date = new Date(value + "Z");
        return date.toDateString() + " " + date.toLocaleTimeString();
    }

}
