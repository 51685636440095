<div class="row " *ngIf="canSearch">
    <div class="col-md-4 d-flex">
        <input (keyup)="onKeyUpSearch()" [(ngModel)]="term" type="text" class="form-control input-gray"
               placeholder="Search">
        <div class="input-group-append mt-auto">
            <button class="btn search-icon btn-outline-secondary border-0" type="button">
                <fa-icon [icon]="faSearch"></fa-icon>
            </button>
        </div>
    </div>
    <div class="col"></div>
</div>
<div class="table-responsive col-md-12 mt-5 reveal fadeInUp justify-content-center">
    <div class="row" style="--bs-gutter-x:0">

        <table id="table" class="w-100 mb-4" style="font-size: 15px">

            <thead>
            <tr class="table-gray-header">
                <th *ngFor="let header of headers" class="ps-4">
                    <div [class.text-center]="
                        header.title == 'Status' ||  header.title == 'Action'"
                         [class.text-end]="
                          header.title == 'Total Amount' ||
                          header.title == 'Amount' ||
                          header.title == 'Refund Amount' ||
                          header.title == 'Final Merchant Amount'"
                    >
                        {{header.title}}
                    </div>
                </th>
            </tr>
            </thead>

            <tbody>
            <ng-content select="[body]"></ng-content>
            </tbody>

        </table>
    </div>
</div>
<div class="w-100 text-center disabled" *ngIf="data?.length == 0" style="opacity: 0.4">No data found</div>
<div class="row mt-4">
    <div class="col-sm-8">
        <ng-content select="[footer]"></ng-content>
    </div>
    <div class="col-sm-4">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="collectionSize" [(page)]="currentPage"
                        [boundaryLinks]="true"
                        [pageSize]="pageSize" (pageChange)="pageClick($event)">
            <ng-template ngbPaginationPages let-page let-pages="pages">
                <li class="ngb-custom-pages-item" *ngIf="pages.length > 0">
                    <div class="form-group d-flex flex-nowrap px-2">
                        <input #i
                               type="text"
                               inputmode="numeric"
                               pattern="[0-9]*"
                               class="form-control custom-pages-input text-center"
                               id="paginationInput"
                               [value]="page"
                               (keyup.enter)="selectPage(i.value)"
                               (blur)="selectPage(i.value)"
                               (input)="formatInput($any($event).target)"
                               aria-labelledby="paginationInputLabel paginationDescription"
                               style="width: 3rem"
                        />
                        <span id="paginationDescription" class="col-form-label text-nowrap px-2">
                  of {{pages.length}}</span>
                    </div>
                </li>
            </ng-template>

        </ngb-pagination>
    </div>
</div>
