import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent implements OnInit {
  @Input() modal: any;
  @Input() header = "Change Image";
  @Input() imageAspectRatio = "4 / 3";
  @Input() imageFormat = "png";
  @Output() clickButton = new EventEmitter();
  @Input() imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  cropImage(){
    this.clickButton.emit(this.croppedImage);
    this.modal.dismiss("close click");
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }


}
