<div class="card" style="height: 33vh;">
    <div class="card-body">
        <div class="row">
            <div class="col-8">
                <label class="h6">{{merchantPrimaryCard.title}}</label>
            </div>
            <div class="col-4 d-flex justify-content-end">
                <a role="button" class="more" style="color: #2EAFF8;font-size: 14px;">{{merchantPrimaryCard.more}}</a>
            </div>
        </div>
        <div class="row h-75">
            <div class="col d-flex justify-content-center align-items-center">
                <ul class="list-group" style="text-align: center; list-style: none;">
                    <li class=""><label class="h6">{{merchantPrimaryCard.currency}}</label></li>
                    <li class=""><label class=" h3">{{merchantPrimaryCard.amount}}</label></li>
                    <li class=""><label style="font-size: 12px;color: red;font-weight:
                        400;">{{merchantPrimaryCard.duration}}</label></li>
                </ul>
            </div>
        </div>
    </div>
</div>