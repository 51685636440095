<div class="card mt-3">
    <div class="card-body">
        <div class="row">
            <div class="col">
                <img style="width: 50px;height: 50px" src="{{card.image}}" alt="">
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-md-9">
                        <label class="lbl-title">
                            {{card.title}} <br>
                        </label>
                    </div>
                    <div class="col-md-4">
                        <label class="lbl-value">
                            {{card.value}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
