<div class="modal-container">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="content">
        <div class="modal-header">
            <p class="modal-title">{{ header }}</p>
        </div>

        <div class="form-group form-floating ms-2 me-2">
            <ng-content></ng-content>
        </div>

        <div class="modal-footer" *ngIf="buttonText">
            <button type="button" class="btn btn-light" (click)="buttonClick()"> {{buttonText}} </button>
        </div>
    </div>
</div>
