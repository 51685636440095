import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule, NgbModule, NgbToastModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SideBarComponent } from './side-bar/side-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StatusChipComponent } from './chip/status-chip/status-chip.component';
import { DashboardCardComponent } from './cards/dashboard-card/dashboard-card.component';
import { CategoryCardComponent } from './select-business-type/category-card/category-card.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { VerticalCenterModalComponent } from './modal/vertical-center-modal/vertical-center-modal.component';
import { TableComponent } from './table/table.component';
import { UploadFileComponent } from './select-business-type/upload-file/upload-file.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LoaderComponent } from './loader/loader.component';
import { ToastComponent } from './toast/toast.component';
import { MerchantPrimaryCardComponent } from './cards/merchant-primary-card/merchant-primary-card.component';
import { PaylinkListCardComponent } from './cards/paylink-list-card/paylink-list-card.component';
import { TextCardComponent } from './cards/text-card/text-card.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { PaylinkCardComponent } from './cards/paylink-card/paylink-card.component';
import { ExtraItemComponent } from './extra-item/extra-item/extra-item.component';
import { NameBarComponent } from './name-bar/name-bar.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { ImageCropperModalComponent } from './modal/image-cropper-modal/image-cropper-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfileInfoCardComponent } from './profile-info-card/profile-info-card.component';
import { CopyUrlModalComponent } from './modal/copy-url-modal/copy-url-modal.component';
import {TruncatePipe} from "./pips/truncate.pipe";
import {TextLoadingComponent} from "./skeleton/text-loading/text-loading.component";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {ImageLoadingComponent} from "./skeleton/image-loading/image-loading.component";
import {UtcLocal} from "./utc-local";
import {ConfirmationModalComponent} from "./modal/confirmation-modal/confirmation-modal.component";


@NgModule({
    declarations: [
        SideBarComponent,
        TextLoadingComponent,
        ImageLoadingComponent,
        CategoryCardComponent,
        VerticalCenterModalComponent,
        NavBarComponent,
        StatusChipComponent,
        DashboardCardComponent,
        TableComponent,
        UploadFileComponent,
        LoaderComponent,
        ToastComponent,
        MerchantPrimaryCardComponent,
        PaylinkListCardComponent,
        TextCardComponent,
        TitleBarComponent,
        PaylinkCardComponent,
        ExtraItemComponent,
        NameBarComponent,
        CustomTableComponent,
        ImageCropperModalComponent,
        ConfirmationModalComponent,
        ProfileInfoCardComponent,
        CopyUrlModalComponent,
        TruncatePipe,
        UtcLocal
    ],

    imports: [
        CommonModule,
        NgbCollapseModule,
        RouterModule,
        NgbToastModule,
        FormsModule,
        NgbTypeaheadModule,
        FontAwesomeModule,
        NgbModule,
        NgxDropzoneModule,
        ImageCropperModule,
        NgxSkeletonLoaderModule,
    ],
    exports: [
        SideBarComponent,
        NameBarComponent,
        CategoryCardComponent,
        NavBarComponent,
        StatusChipComponent,
        DashboardCardComponent,
        VerticalCenterModalComponent,
        TableComponent,
        UploadFileComponent,
        LoaderComponent,
        ToastComponent,
        MerchantPrimaryCardComponent,
        PaylinkListCardComponent,
        TextCardComponent,
        TitleBarComponent,
        PaylinkCardComponent,
        ExtraItemComponent,
        CustomTableComponent,
        ImageCropperModalComponent,
        ConfirmationModalComponent,
        ProfileInfoCardComponent,
        CopyUrlModalComponent,
        TruncatePipe,
        TextLoadingComponent,
        ImageLoadingComponent,
        UtcLocal
    ]
})

export class SharedModule {
}
