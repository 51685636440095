import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-text-loading',
  templateUrl: './text-loading.component.html',
  styleUrls: ['./text-loading.component.scss']
})
export class TextLoadingComponent implements OnInit {

  @Input() text = null;
  @Input() width = '80%';
  @Input() height = '20px';
  @Input() isLoaded = null;
  constructor() { }

  ngOnInit(): void {
  }

}
