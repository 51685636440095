<div class="modal-container">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="content">
        <div class="modal-header">
            <p class="modal-title">{{ header }}</p>
        </div>
        <div class="form-group form-floating ms-2 me-2">
            <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="true"
                    [aspectRatio]= "imageAspectRatio"
                    format="{{ imageFormat }}"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady()"
                    (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="cropImage()"> Crop & Save </button>
        </div>
    </div>
</div>
