import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-image-loading',
    templateUrl: './image-loading.component.html',
    styleUrls: ['./image-loading.component.scss']
})
export class ImageLoadingComponent implements OnInit {

    @Input() src = null;
    @Input() object = null;
    @Input() width = '80%';
    @Input() height = '20px';
    @Input() isLoaded = true;

    constructor() {
    }

    ngOnInit(): void {
        this.setTimeout();
    }

    setTimeout() {
        setTimeout(() => {
            if (!this.src) {
                // this.isLoaded = false;
            }
        }, 2000);
    }

}
