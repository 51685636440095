import {Component} from '@angular/core';

import {UserService} from './services/user-service/user.service';
import {Net, NetService} from './commons/net/net.service';
import {Endpoint} from './commons/net/endpoint';
import {Const} from './commons/const';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'base';

    //TODO  NEED TO CHECK IS USER LOGIN
    shouldNavBarShow = false;

    constructor(private netService: NetService, public userService: UserService) {

    }


    appComponentLoadEvent(event: any) {
        if (event.page === Const.LOGIN || event.page === Const.REGISTER){
            this.shouldNavBarShow = false;
            return;
        }
        this.shouldNavBarShow = true;
    }
}
