import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MaintenacneService {

    private isMaintenance = new Subject<string>();
    dataTransferObservable = this.isMaintenance.asObservable();

    constructor() {
    }

    maintenance(str) {
      this.isMaintenance.next(str);
    }

}
