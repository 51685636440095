import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PaylinkInformation} from '../../../models/PaylinkInfomation';
import {StateService} from '../../../services/state-service/state.service';
import {CommonUtils} from '../../../services/utility/common-utils';
import {MetaData} from '../../../models/MetaData';
import {Const} from '../../../commons/const';
import {PaylinkService} from '../../../services/paylink/paylink.service';
import {ToastService} from '../../../services/toast/toast.service';

@Component({
    selector: 'app-copy-url-modal',
    templateUrl: './copy-url-modal.component.html',
    styleUrls: ['./copy-url-modal.component.scss']
})
export class CopyUrlModalComponent implements OnInit {
    @Input() modal: any;
    @Input() id: any;
    header = 'Change Image';
    isUrlCreated = false;
    paylinkInformation: PaylinkInformation;
    firstname: string;
    lastname: string;
    name = new MetaData(Const.RECEIVER_NAME_KEY, Const.RECEIVER_TYPE);
    email = new MetaData(Const.RECEIVER_EMAIL_KEY, Const.RECEIVER_TYPE);
    contact = new MetaData(Const.RECEIVER_CONTACT_KEY, Const.RECEIVER_TYPE);
    dueDate = new MetaData(Const.RECEIVER_DUEDATE_KEY, Const.RECEIVER_DUEDATE_TYPE);
    payLinkMeta: any;
    displayPrice: string;
    paymentUrl: any;
    isPaymentLinkCopied = false;

    constructor(private modalService: NgbModal,
                private stateService: StateService,
                private paylinkService: PaylinkService,
                private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.paylinkInformation = this.stateService.shareData_paylinkInformation;
        this.stateService.shareData_paylinkInformation = undefined;
        this.displayPrice = CommonUtils.getPriceCardDisplayValue(this.paylinkInformation.grossTotal.toString());
    }

    generateLink() {
        if (CommonUtils.isEmpty(
            this.firstname,
            this.lastname,
            this.email.value,
            this.contact.value,
            this.dueDate.value)) {
            this.toastService.showError("Please fill all the details!");
            return;
        }
        this.name.value = this.firstname + ' ' + this.lastname;

        this.payLinkMeta = {
            'payLinkMeta': [
                this.name,
                this.email,
                this.contact,
                this.dueDate
            ]
        };

        this.copyPayLink(this.id, this.payLinkMeta);
    }

    copyPayLink(id: any, body) {
        this.paylinkService.generatePayLink(
            id, body
        ).subscribe(data => {
            this.paymentUrl = data.url;
            this.isUrlCreated = true;
            this.paylinkInformation.editable = false;
        }, error => {
            this.toastService.showError('Error generating paylink, Please try again!');
            this.modal.dismiss('close click');
        });
    }

    copyPaylinkToClipBoard() {
        CommonUtils.copyToClipboard(this.paymentUrl);
        this.isPaymentLinkCopied = true;
        setTimeout(() => {
            this.isPaymentLinkCopied = false;
        }, 5000);
    }
}