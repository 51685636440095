import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

    @Input() table: any;
    @Input() headers: any;
    @Input() data: any;

    @Input() currentPage;
    @Input() pageSize;
    @Input() collectionSize;

    faSearch = faSearch;


    // tslint:disable-next-line:no-output-on-prefix
    @Output() onPageClick: EventEmitter<any> = new EventEmitter();
    term: any = '';
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onSearchKeyUp: EventEmitter<any> = new EventEmitter();

    @Output() goClick: EventEmitter<any> = new EventEmitter();
    @Output() excelClick: EventEmitter<any> = new EventEmitter();

    toDate: any;
    fromDate: any;


    pageClick(event: number) {
        this.onPageClick.emit(event);
    }

    onKeyUpSearch() {
        this.onSearchKeyUp.emit(this.term);
    }

    go($event: MouseEvent) {
        let json = {
            'term': this.term,
            'startDate': this.fromDate,
            'endDate': this.toDate
        };
        this.goClick.emit(json);
    }

    excel() {
        this.excelClick.emit();
    }
}


