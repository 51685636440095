
<div class="row mt-2">
    <div class="col-sm-2"></div>
    <div class="col-sm-5">
        <input type="text" placeholder="Type hera extra (shipping,discount)" #extraKey='ngModel' required
               class="form-control pe-0 fw-light text-end mt-2 input-primary " [(ngModel)]="item.key">
        <div *ngIf="extraKey.invalid && (extraKey.dirty || extraKey.touched)"
             class="text-danger position-absolute">
            <div *ngIf="extraKey.errors.required" class="error-label">
                required.
            </div>
        </div>
    </div>
    <div class="col-sm-5 d-flex">
        <input type="number" name="extra" placeholder="0.00" min="0" (keyup)="calculateTotal($event)"
               class="form-control mt-2 text-end input-primary" [(ngModel)]="item.value" #extraValue='ngModel' required>

        <fa-icon style="height: fit-content;margin-right: -15px" class="mt-auto" [icon]="faMinusCircle" (click)="delete()"></fa-icon>
    </div>

</div>
