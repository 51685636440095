import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-merchant-primary-card',
  templateUrl: './merchant-primary-card.component.html',
  styleUrls: ['./merchant-primary-card.component.scss']
})
export class MerchantPrimaryCardComponent implements OnInit {

  @Input() merchantPrimaryCard: any;

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  toPaylinks(urlString: string) {
    this.router.navigateByUrl(urlString);
  }
}
