import {Injectable} from '@angular/core';
import {Net, NetMethod, NetService} from '../../commons/net/net.service';
import {Endpoint} from '../../commons/net/endpoint';
import {SessionService} from '../session/session.service';
import {map} from 'rxjs/operators';
import {Log} from '../../commons/log';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private mock = false;

    constructor(private netService: NetService, private sessionService: SessionService) {
    }

    login(email: string, password: string) {
        const net = new Net(
            NetMethod.post,
            Endpoint.withUrl(Endpoint.LOGIN),
            {
                email: email,
                password: password
            }
        );
        net.forceDispatchErrorCodes = [400, 401];
        return this.netService.process(net).pipe(map(response => {
            if (response) {
                this.sessionService.setUserJson(JSON.stringify(response));
                return response;
            }
            return null;
        }));
    }

    logout(): void {
        this.sessionService.setUserJson(null);
        this.sessionService.setUserToken(null);
        this.sessionService.clearUserData();
    }

    getUser() {
        return this.sessionService.getCurrentUser();
    }

    public isUserLoggedIn(): boolean {
        if (this.mock) {
            return true;
        }
        return this.isAnyUserLoggedIn() && this.sessionService.getCurrentUser().type == 'USER';
    }

    public isAdminLoggedIn(): boolean {
        if (this.mock) {
            return true;
        }
        return this.isAnyUserLoggedIn() && this.sessionService.getCurrentUser().type == 'ADMIN';
    }

    public isBankUserLoggedIn(): boolean {
        if (this.mock) {
            return true;
        }
        return this.isAnyUserLoggedIn() && this.sessionService.getCurrentUser().type == 'BANK';
    }

    public isAnyUserLoggedIn(): boolean {
        return this.sessionService.getCurrentUser();
    }

}
