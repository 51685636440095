<div *ngIf="text">{{text}}</div>
<div *ngIf="!text">
    <ngx-skeleton-loader [theme]="{
            width: width,
           'border-radius': '0',
            height: height,
           'margin-bottom': '3px'
       }">
    </ngx-skeleton-loader>
</div>
