import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {NetError} from './net-error';
import {Endpoint} from './endpoint';
import {Log} from '../log';
import {Storage} from '../storage';
import {Observable, throwError} from 'rxjs';
import {SessionService} from '../../services/session/session.service';
import {Router} from '@angular/router';
import {UserService} from '../../services/user-service/user.service';
import {Const} from '../const';

@Injectable({
    providedIn: 'root'
})
export class NetService {

    static TOKEN_HEADER = 'Authorization';
    static ERROR_HANDLING_HEADER_NAME = 'error_handler';

    static authorizationToken = null;

    constructor(private http: HttpClient, private router: Router, private storage: Storage) {
    }

    process(net: Net): Observable<any> {
        switch (net.method) {
            case NetMethod.get:
                return this.get(net);
            case NetMethod.post:
                return this.post(net);
            case NetMethod.delete:
                return this.delete(net);
            case NetMethod.put:
                return this.put(net);
        }
    }

    private get(net: Net): Observable<any> {
        return this.http.get(net.endpoint.url, {
            headers: this.getHeaders(net),
            params: net.body,
            observe: 'response'
        }).pipe(map(response => {
            return this.extractResponse(response, net);
        }));
    }

    private post(net: Net): Observable<any> {
        return this.http.post(net.endpoint.url, net.body, {
            headers: this.getHeaders(net),
            observe: 'response'
        }).pipe(map(response => {
            return this.extractResponse(response, net);
        }));
    }

    private put(net: Net): Observable<any> {
        return this.http.put(net.endpoint.url, net.body, {
            headers: this.getHeaders(net),
            observe: 'response'
        }).pipe(map(response => {
            return this.extractResponse(response, net);
        }));
    }

    private delete(net: Net): Observable<any> {
        return this.http.delete(net.endpoint.url, {
            headers: this.getHeaders(net),
            params: net.body,
            observe: 'response'
        }).pipe(map(response => {
            return this.extractResponse(response, net);
        }));
    }


    private extractResponse(response, net: Net): any {
        net.response = response;
        if (net.storeAuthToken) {
            NetService.authorizationToken = response.headers.get('authorization');
            this.storage.store(NetService.TOKEN_HEADER, NetService.authorizationToken);
        }
        return response.body;
    }

    private getHeaders(net: Net): HttpHeaders {
        const defaultHeaders = net.headers;
        NetService.authorizationToken = this.storage.get(NetService.TOKEN_HEADER);

        if (NetService.authorizationToken && net.sendAuthToken) {
            defaultHeaders[NetService.TOKEN_HEADER] = NetService.authorizationToken;
        }
        if (net.forceDispatchErrorCodes.length > 0) {
            defaultHeaders[NetService.ERROR_HANDLING_HEADER_NAME] = JSON.stringify(net.forceDispatchErrorCodes);
        }
        return new HttpHeaders(defaultHeaders);
    }

}


export class NetMethod {
    static get = 'get';
    static post = 'post';
    static delete = 'delete';
    static put = 'put';
}

export class Net {
    endpoint: Endpoint;
    body = {};
    method = 'get';
    headers = {};
    forceDispatchErrorCodes = [];
    response: any;
    storeAuthToken = true;
    sendAuthToken = true;
    uniqueId: string = null;

    constructor(method: string, endpoint: Endpoint, body = {}, headers = {}) {
        this.method = method;
        this.endpoint = endpoint;
        this.body = body;
        this.headers = headers;
        this.uniqueId = Math.floor(Math.random() * 100) + '-' + Math.floor(Math.random() * 100) + '-' + Math.floor(Math.random() * 100);
    }

    static get(endpoint: Endpoint, body = {}): Net {
        return new Net(NetMethod.get, endpoint, body);
    }

    static post(endpoint: Endpoint, body = {}): Net {
        return new Net(NetMethod.post, endpoint, body);
    }

    static put(endpoint: Endpoint, body = {}): Net {
        return new Net(NetMethod.put, endpoint, body);
    }

}
