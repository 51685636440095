import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {faTachometerAlt} from '@fortawesome/free-solid-svg-icons';
import {faUserCircle} from '@fortawesome/free-solid-svg-icons';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {faCoins, faBars, faMoneyBill, faArrowLeft, faTimes, faReceipt} from '@fortawesome/free-solid-svg-icons';
import {UserService} from '../../services/user-service/user.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
    faTachometerAlt = faTachometerAlt;
    faUserCircle = faUserCircle;
    faReceipt = faReceipt;
    faCharge = faMoneyBill;
    faExclamationTriangle = faExclamationTriangle;
    faCoins = faCoins;
    faBars = faBars;
    sideBarIcon = faTimes;

    isInvoiceCollapsed = false;
    isPaylinkCollapsed = false;
    data = null;
    isSideBarOpened = true;
    pageRoot = "Merchant /";
    page = "Dashboard";

    constructor(public userService: UserService, private router: Router) {

    }

    ngOnInit(): void {
        if (!$('#wrapper').hasClass('toggled')) {
            this.faBars = faArrowLeft;
        } else {
            this.faBars = faBars;
            $('#wrapper').removeClass('toggled');
        }

    }

    dashboard() {
        if (this.userService.isAdminLoggedIn()) {
            this.router.navigateByUrl('/admin/home');
            return;
        }
        this.router.navigateByUrl('/merchants/dashboard');
    }

    toMerchantDashboard() {
        this.router.navigateByUrl('/merchants/dashboard');
    }

    toDailyTransactions() {
        this.router.navigateByUrl('/merchants/dashboard/merchant-transactions');
    }

    menuClick(e) {
        if ($('#wrapper').hasClass('toggled')) {
            this.isSideBarOpened = true;
            this.sideBarIcon = faTimes;
            $('#wrapper').toggleClass('toggled');
        } else {
            this.isSideBarOpened = false;
            this.sideBarIcon = faBars;
            e.preventDefault();
            $('#wrapper').toggleClass('toggled');

        }
    }

    toPaylinks() {
        this.router.navigateByUrl('/merchants/dashboard/paylink-list');
    }


    toPaylinksHistory() {
        this.page = "Paylinks History";
        this.router.navigateByUrl('/merchants/dashboard/paylink-history');
    }

    toCreateInvoice() {
        this.page = "Create invoice";
        this.router.navigateByUrl('/merchants/dashboard/upload-invoice');
    }

    toInvoiceHistory() {
        this.page = "Invoice History";
        this.router.navigateByUrl('/merchants/dashboard/invoice-history');
    }

    toDailyIncome() {

    }

    toPayouts() {
        this.page = "Payouts";
        this.router.navigateByUrl('/merchants/dashboard/payouts');
    }

    sideBarLoad(event: any) {
        this.page = event.page;
    }
}
