<ngx-dropzone (change)="onSelect($event)" [multiple]=false
              [accept]="'image/x-png,image/png,image/jpeg,image/jpg,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" class="drop-zone">

    <ngx-dropzone-label *ngIf="!isFileUploaded">
        <div class="upload-card">
            <img src="assets/merchants/upload.svg" alt="">
            <span class="drag-drop-p">Drag and Drop your files <br>or</span>
            <span class="browse-file-p  btn">Browse Files</span>
        </div>
    </ngx-dropzone-label>

    <ngx-dropzone-label *ngIf="isFileUploaded">
        <div class="file-exist">
            <img src="assets/tick.png" alt="">
            <p class="drag-drop-p">File already uploaded</p>
            <p class="browse-file-p">Click here to re-upload</p>
        </div>
    </ngx-dropzone-label>

    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)" class="preview">
        <ngx-dropzone-label>
            <div class="file-selected">
                <img  class="file-selected-image" src="assets/file-check.png" alt="">
                <span class="file-selected">File selected</span>
                <span class="file-selected-name">{{f?.name}}</span>

                <span class="file-selected-reupload">Click here to re-upload</span>
            </div>
        </ngx-dropzone-label>
    </ngx-dropzone-preview>



</ngx-dropzone>

<div class="row text-center mt-2" *ngIf="progress>0">
    <p>
        <ngb-progressbar type="info" [value]="progress" [striped]="true">{{progress}} % &nbsp; Completed!
        </ngb-progressbar>
    </p>
</div>
