import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Log} from '../log';
import {Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {NetService} from '../net/net.service';
import {NetError} from '../net/net-error';
import {ToastService} from '../../services/toast/toast.service';
import {Router} from '@angular/router';
import {LoadingService} from '../../services/loading/loading.service';
import {MaintenacneService} from "../../services/maintenance/maintenacne.service";
import {CommonUtils} from "../../services/utility/common-utils";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

    constructor(private toastService: ToastService, private router: Router,
                private loadingService: LoadingService, private maintenanceService: MaintenacneService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.maintenanceService.maintenance(null);

        return next.handle(req).pipe(tap((response: any) => {
            if (response && response.status < 300) {

            }
            return response;
        }), catchError((err, caught) => {
            console.log(err);

            let netError = new NetError(CommonUtils.capitalize(err.error.message), err.status, err.error.type);
            if (req.headers.get(NetService.ERROR_HANDLING_HEADER_NAME) != null) {
                let forcedErrorCodes = JSON.parse(req.headers.get(NetService.ERROR_HANDLING_HEADER_NAME));
                if (forcedErrorCodes.includes(err.status)) {
                    return throwError(netError);
                }
            }
            if (err.status == 401 || err.status == 403) {
                this.loadingService.hide();
                this.toastService.showError('Session timeout. Please login again.', 5000, 'session_timeout');
                this.router.navigateByUrl('/');
                return Observable.create();
            }

            if (err.status == 0 || err.status == 502) {
                this.loadingService.hide();
                this.toastService.showError('Something went wrong with our servers', 5000);
                return Observable.create();
            }

            if (err.status < 500) {
                this.loadingService.hide();
                this.toastService.showError('Unexpected error occurred. Please try again later.');
                return Observable.create();
            }

            if (err.status == 503) {
                this.toastService.showError(netError.message, 5000);
                this.maintenanceService.maintenance(netError.message);
                return Observable.create();
            }

            return throwError(netError);
        }));

    }

}
