export class UserModel {
    'id': null;
    'firstName': null;
    'lastName': null;
    'email': null;
    'nic': null;
    'position': null;
    'password': null;
    'contactNumber': null;
    'type': null;
    'step': null;
    'status': null;
    'businessProfile': null;
    'userMerchant': null;
    'userAccountDetails': null;
}
