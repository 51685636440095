<div class="mt-5 mb-3 pe-0 ms-0 me-0">
        <div class="d-flex align-items-center">
                <p class="h6 m-0 flex-grow-1">{{pageRoot}} {{ page }}</p>
                <div class="d-flex justify-content-center btn p-2 setting-img"><img src="assets/setting_icon.svg"></div>
        </div>

        <div class="sidebar-content">
                <div id="sidebar-wrapper" style="height: 100%;">

                </div>
        </div>


</div>