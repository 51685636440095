import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-extra-item',
    templateUrl: './extra-item.component.html',
    styleUrls: ['./extra-item.component.scss']
})
export class ExtraItemComponent implements OnInit {

    faMinusCircle = faMinusCircle;


    // tslint:disable-next-line:no-output-on-prefix
    @Output() onDelete: EventEmitter<any> = new EventEmitter();
    @Output() calculate: EventEmitter<any> = new EventEmitter();
    @Input() item = {
        key: null,
        value: null,
        __id: null
    };

    constructor() {
    }

    ngOnInit(): void {
    }

    delete() {
        this.onDelete.emit();
    }

    calculateTotal($event) {
        this.calculate.emit($event);
    }
}
