import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaylinkInformation} from '../../../models/PaylinkInfomation';
import {ToastService} from '../../../services/toast/toast.service';
import {Const} from '../../../commons/const';
import {CommonUtils} from '../../../services/utility/common-utils';

@Component({
    selector: 'app-paylink-card',
    templateUrl: './paylink-card.component.html',
    styleUrls: ['./paylink-card.component.scss']
})
export class PaylinkCardComponent implements OnInit {
    @Input() card: PaylinkInformation;
    @Output() clickDelete = new EventEmitter();
    @Output() clickEdit = new EventEmitter();
    @Output() clickDisableActive = new EventEmitter();
    @Output() clickCopyPayLink = new EventEmitter();
    activeStatus = Const.ACTIVE;

    constructor(
        private toastService: ToastService
    ) {
    }

    ngOnInit(): void {
        // this.setCardDisplayValue(this.card);
    }

    deletePaylink(id) {
        this.clickDelete.emit(id);
    }

    disableActivePaylink(id){
        this.clickDisableActive.emit(id);
    }

    editPaylink(card) {
        if (!card.editable){
            return;
        }

        this.clickEdit.emit(card.id);
    }

    copyPayLink(id: string) {
        this.clickCopyPayLink.emit(id);
    }

    getCardDisplayValue(): string {
        return CommonUtils.getPriceCardDisplayValue(this.card.grossTotal.toString());
    }
}
