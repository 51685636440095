<div class="card details-container p-0">
    <div class="card-header text-center">
        <p class="h6 heading-title ">{{infoCategory}}</p>
    </div>

    <div class="card-body p-0">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 p-5">
                <ul class="list-group">
                    <li class="list-group-item w-100" *ngFor="let info of infoLefSection">
                        <div class="d-flex flex-row">
                            <div class="bd-highlight info-text">{{info.title}}</div>
                            <div class="bd-highlight flex-grow-1">{{info.description}}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 p-5">
                <ul class="list-group">
                    <li class="list-group-item w-100" *ngFor="let info of infoRightfSection">
                        <div class="d-flex flex-row">
                            <div class="bd-highlight info-text">{{info.title}}</div>
                            <div class="bd-highlight flex-grow-1">{{info.description}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>