import {Injectable} from '@angular/core';
import {Net, NetMethod, NetService} from '../../commons/net/net.service';
import {Endpoint} from '../../commons/net/endpoint';
import {PaylinkFormData} from '../../models/PaylinkFormData';
import {SearchQuery} from '../../models/SearchQuery';

@Injectable({
    providedIn: 'root'
})
export class PaylinkService {

    constructor(private netService: NetService) {
    }

    generatePayLink(userId, body) {
        const net = new Net(
            NetMethod.post,
            Endpoint.withPathParam(
                Endpoint.GENERATE_PAYLINK,
                {'id': userId}),
            body
        );
        return this.netService.process(net);
    }
}
