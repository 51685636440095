import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-name-bar',
  templateUrl: './name-bar.component.html',
  styleUrls: ['./name-bar.component.scss']
})
export class NameBarComponent implements OnInit {
  @Input() page: string;
  @Input() pageRoot: string;
  constructor() { }

  ngOnInit(): void {
  }

}
