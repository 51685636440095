<div class="page-wrapper h-100" >
    <div class="d-flex h-100" id="wrapper">
        <div class="sidebar-content pt-5">
            <div id="sidebar-wrapper">
<!--                <div (click)="menuClick($event)" id="menu-toggle"-->
<!--                     class="navbar-toggler button-card right border-0 ms-1 mt-4 {{ (!this.isSideBarOpened) ? 'out' : '' }}"-->
<!--                     data-toggle="collapse" data-target="#collapsibleNavbar">-->
<!--                    <fa-icon [icon]="sideBarIcon"></fa-icon>-->
<!--                </div>-->
                <div class="list-group list-group-flush border-0">
                    <div class="sidebar-menu border-0 mt-4">
                        <br>
                        <ul>
                            <a *ngIf="userService.isAdminLoggedIn()" (click)="dashboard()"
                               class="list-group-item list-group-item-action border-0">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <fa-icon [icon]="faTachometerAlt"></fa-icon>
                                        </td>
                                        <td>Dashboard</td>
                                    </tr>
                                </table>
                            </a>

                            <ul #invoice="ngbCollapse" [(ngbCollapse)]="isInvoiceCollapsed" id="invoice">
                                <li *ngIf="!userService.isAdminLoggedIn() && !userService.isBankUserLoggedIn()">
                                    <a class="list-group-item list-group-item-action border-0" role="button"
                                       (click)="toCreateInvoice()">Create invoice</a>
                                </li>
                                <li *ngIf="!userService.isAdminLoggedIn() && !userService.isBankUserLoggedIn()">
                                    <a (click)="toInvoiceHistory()" role="button"
                                       class="list-group-item list-group-item-action border-0">Invoice History</a>
                                </li>
                            </ul>

                            <a *ngIf="userService.isBankUserLoggedIn()" routerLink="/bankuser/home/payouts"
                               class="list-group-item border-0 list-group-item-action ">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <img src="assets/atm-icon.svg" alt="" style="width: 55%"> &nbsp;
                                        </td>
                                        <td>Payouts</td>
                                    </tr>
                                </table>
                            </a>

                            <a *ngIf="userService.isAdminLoggedIn() || userService.isBankUserLoggedIn()" routerLink="merchant"
                               class="list-group-item list-group-item-action border-0">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <fa-icon [icon]="faUserCircle"></fa-icon>
                                        </td>
                                        <td>Merchants</td>
                                    </tr>
                                </table>
                            </a>

                            <a *ngIf="userService.isBankUserLoggedIn()" routerLink="other-bank"
                               class="list-group-item list-group-item-action border-0">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <fa-icon [icon]="faCharge"></fa-icon>
                                        </td>
                                        <td>Other Bank Charges</td>
                                    </tr>
                                </table>
                            </a>

                            <a *ngIf="userService.isAdminLoggedIn()"
                               class="list-group-item border-0 list-group-item-action ">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <fa-icon [icon]="faExclamationTriangle"></fa-icon>
                                        </td>
                                        <td>Inquiries</td>
                                    </tr>
                                </table>
                            </a>
                            <a *ngIf="userService.isAdminLoggedIn()" routerLink="revenue"
                               class="list-group-item border-0 list-group-item-action ">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <fa-icon [icon]="faCoins"></fa-icon>
                                        </td>
                                        <td>Revenue</td>
                                    </tr>
                                </table>
                            </a>
                            <a *ngIf="userService.isAdminLoggedIn()" routerLink="transaction"
                               class="list-group-item  border-0 list-group-item-action ">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <img src="assets/transaction-icon.svg" alt="" style="width: 58%">
                                        </td>
                                        <td>Transactions</td>
                                    </tr>
                                </table>
                            </a>
                            <a *ngIf="userService.isAdminLoggedIn()" routerLink="refunds"
                               class="list-group-item border-0 list-group-item-action ">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <fa-icon style="width: 10%" [icon]="faReceipt"></fa-icon>
                                        </td>
                                        <td>Refunds</td>
                                    </tr>
                                </table>
                            </a>
                            <a *ngIf="userService.isAdminLoggedIn()" routerLink="chargeback"
                               class="list-group-item border-0 list-group-item-action ">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <fa-icon style="width: 10%" [icon]="faCharge"></fa-icon>
                                        </td>
                                        <td>Chargeback</td>
                                    </tr>
                                </table>
                            </a>
                            <a *ngIf="userService.isAdminLoggedIn()" routerLink="payouts"
                               class="list-group-item border-0 list-group-item-action ">
                                <table>
                                    <tr>
                                        <td style="width: 30px">
                                            <img src="assets/atm-icon.svg" alt="" style="width: 55%"> &nbsp;
                                        </td>
                                        <td>Payouts</td>
                                    </tr>
                                </table>
                            </a>

                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div id="page-content-wrapper"  class="h-100 pt-5 ps-3 pe-3 mt-5">
            <app-name-bar *ngIf="!userService.isAdminLoggedIn() && !userService.isBankUserLoggedIn()" [page]="this.page" [pageRoot]="this.pageRoot">
            </app-name-bar>
            <router-outlet (activate)="sideBarLoad($event)"></router-outlet>
        </div>
    </div>
</div>
