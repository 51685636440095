export class MetaData {
    key: string;
    value: string;
    type: string;


    constructor(key: string, type: string) {
        this.key = key;
        this.type = type;
    }
}