import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-vertical-center-modal',
    templateUrl: './vertical-center-modal.component.html',
    styleUrls: ['./vertical-center-modal.component.scss']
})
export class VerticalCenterModalComponent implements OnInit {
    @Input() modal: any;
    @Input() header = "Example Header";
    @Input() buttonText = null;
    @Input() canDrop = true;
    @Output() clickButton = new EventEmitter();

    constructor(private modalService: NgbModal) {
    }

    ngOnInit(): void {
    }


    buttonClick() {
      this.clickButton.emit(null);
      if (this.canDrop) {
            this.modal.dismiss("close click");
        }
    }
}
