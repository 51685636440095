<div class="modal-container">
    <button type="button" class="close position-absolute" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="content">
        <div class="modal-header">
            <p class="modal-title color-danger">{{headerTxt}}</p>
        </div>
        <div class="form-group ms-3 me-3">
            <p class="mt-2">
                {{bodyTxt}} </p>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-light mt-2" (click)="confirm()">{{btnTxt}}</button>
        </div>
    </div>
</div>

