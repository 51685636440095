<div class="p-1" >
    <div class="card">
        <div class="row no-gutters">
            <div class="col-md-2 col-sm-2 d-flex justify-content-center align-items-center"
                *ngFor="let templateMeta of paylink.templateMetas">
                <img src="{{templateMeta.value}}" class="card-img-top" alt="...">
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="card-body">
                    <h6 class="card-title">{{paylink.name}}</h6>
                    <p class="card-text">{{paylink.description}}</p>
                </div>
            </div>
            <div class="col-md-2 col-sm-7 d-flex justify-content-center align-items-center"
                 style="cursor: copy" (click)="generatePayLink(content)">
                <img src="assets/merchants/copy-image.png" alt="" style="width: 4vh;height: 4vh;"
                     >
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <app-copy-url-modal
            [modal]="modal"
            [id]="paylink.id">
    </app-copy-url-modal>
</ng-template>