import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {SharedModule} from './components/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SessionService} from './services/session/session.service';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NetService} from './commons/net/net.service';
import {Storage} from './commons/storage';
import {InterceptorProvider} from './commons/interceptors/InterceptorProvider';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,      
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        FontAwesomeModule

    ],
    providers: [NetService, SessionService, Storage, HttpClient, Storage, InterceptorProvider],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
}
