import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '', loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'admin', loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
    },
    { path: 'bankuser', loadChildren: () => import('./views/bank-user/bank-user.module').then(m => m.BankUserModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
