import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  shareData_paylinkInformation: any;
  shareData_souldPaylinkCardUpdate: any;
  shareData_currentBusinessSelectionStage: any;
  constructor() { }

  public clearValues(){
    this.shareData_paylinkInformation = null;
    this.shareData_souldPaylinkCardUpdate = null;
  }
}
