import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CategoryCard } from '../../../models/CategoryCard';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss']
})
export class CategoryCardComponent implements OnInit {
  @Input() card: CategoryCard;
  @Output() cardClick = new EventEmitter();
  @Input() isCardSelected = true;

  constructor() { }

  ngOnInit(): void {
  }

  buttonClick() {
    this.cardClick.emit();
  }

}
