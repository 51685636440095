<div class="payload-card-auto paylink-custom-card">
    <div class="btn-more-container">
        <div class="dropdown btn-more dropbtn">
            <div class="dropdown-content">
                <a (click)="editPaylink(card)" *ngIf="card.editable">Edit</a>
                <a (click)="disableActivePaylink(card.id)">{{ (card.status != activeStatus) ? 'Active' : 'Disable'
                    }}</a>
                <a (click)="deletePaylink(card.id)">Delete</a>
            </div>
        </div>
    </div>
    <div class="image-container">
        <img src="{{ card.templateMetas[0].value }}" alt="" />
    </div>
    <div class="price-container px-2">
        <p>${{ getCardDisplayValue() }}</p>
    </div>
    <div class="row text-container">
        <p class="default-p title">{{ card.name }}</p>
        <div class="description-container">
            <p class="default-p description">{{ card.description }}</p>
        </div>
    </div>
    <div class="row button-container mx-0">
        <button class="btn-primary btn-w-h {{ (card.status != activeStatus) ? 'disable' : ''}}"
            (click)="copyPayLink( card.id )" [disabled]="(card.status != activeStatus) ? true : false"> {{ (card.status
            != activeStatus) ? 'Link Disabled' : 'Copy Link'}}</button>
    </div>
</div>