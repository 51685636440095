import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
    @Input() btnTxt: any = null;
    @Input() bodyTxt: any = null;
    @Input() headerTxt: any = null;
    @Output() confirmClick: EventEmitter<any> = new EventEmitter();
    @Input() modal: any;


    constructor() {
    }

    ngOnInit(): void {
    }

    confirm() {
        this.confirmClick.emit(true);
    }
}
