<div class="col-12">
    <h6>Filter By</h6>
</div>
<form action="" class="form">
    <div class="row">
        <div class="col-12 col-sm-3 form-floating">
            <input type="date" id="dateRange1" name="fromDate" class="form-control input-primary"
                   [(ngModel)]="fromDate">
            <label class="placeholder-text" style="margin-top: -2px" for="dateRange1">Start Date</label>
        </div>
        <div class="col-12 col-sm-3 form-floating mt-1">
            <input type="date" id="dateRange2" name="toDate" class="form-control input-primary" [(ngModel)]="toDate">
            <label class="placeholder-text" style="margin-top: -2px" for="dateRange2">End Date</label>
        </div>
        <div class="col-4 col-sm-1 mt-1 d-flex justify-content-center p-2">
            <button (click)="go($event)" class="w-75 btn btn-primary m-auto">Go</button>
        </div>
        <div class="col-4 col-sm-1 mt-1 d-flex justify-content-center p-2">
            <button class="w-75 btn btn-primary excel-btn" (click)="excel()">
                <img src="assets/icon-excel.svg" alt="">
            </button>
        </div>

        <div class="col-4 col-sm-1 mt-1">

        </div>
        <div class="col-12 col-sm-3 form-floating mt-1">
            <input (keyup)="onKeyUpSearch()" [(ngModel)]="term" name="term" type="search"
                   class="form-control input-primary"
                   placeholder="search" id="searchField">
            <label class="placeholder-text" style="margin-top: -2px" for="searchField">Search</label>
        </div>
    </div>
</form>

<div class="table-responsive col-md-12 mt-4 reveal fadeInUp justify-content-center">
    <div class="row">
        <table id="table" class="w-100 mb-4" style="font-size: 15px">
            <thead class="thead-tr">
            <tr>
                <th *ngFor="let header of headers">
                    <div style="text-align: left !important;" class="ps-2"
                         [class.text-center]="header.title == 'Status' || 'Action'">
                        {{header.title}}
                    </div>
                </th>
            </tr>
            </thead>

            <tbody>
            <ng-content select="[body]"></ng-content>
            </tbody>

        </table>
    </div>
</div>
<div class="row mt-4">
    <div class="col-sm-8">
        <ng-content select="[footer]"></ng-content>
    </div>
    <div class="col-sm-4">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="collectionSize" [(page)]="currentPage"
                        [pageSize]="pageSize" (pageChange)="pageClick($event)">

        </ngb-pagination>
    </div>
</div>
