/**
 * Created by imal365 on 7/23/18.
 */
import {EventEmitter, Injectable} from '@angular/core';
import {Storage} from '../../commons/storage';

@Injectable()
export class SessionService {

    private user = null;
    private userObjectChange = new EventEmitter<any>();


    constructor(
        private storageProvider: Storage
    ) {
    }


    public isSignedIn() {
        return this.storageProvider.get('isSigned');
    }

    public setSignedIn(isTrue: boolean) {
        if (!isTrue) {
            this.storageProvider.clearAll();
        }
        this.storageProvider.store('isSigned', isTrue);
    }

    /*
    * Will subscribe to all user changes and make call backs
    * */
    public getCurrentUserAsync(callback: (user) => void) {
        let user = this.getUser();
        if (user) {
            callback(user);
        }
        this.userObjectChange.subscribe(callback);
    }

    /*
    * Will get the current user at that time
    * */
    public getCurrentUser() {
        return this.getUser();
    }


    public setUserJson(json) {
        this.storageProvider.store(Storage.USER_KEY, json);
        if (this.getUser()) {
            this.userObjectChange.emit(this.getUser());
        }
    }

    public setUserToken(token) {
        this.storageProvider.store(Storage.DEFAULT_TOKEN_KEY, token);
    }

    public getUserToken() {
        return this.storageProvider.get(Storage.DEFAULT_TOKEN_KEY);
    }

    public clearUserData() {
        this.storageProvider.clearAll();
        this.user = null;
    }

    public appendTokenTOUrl(url: string) {
        let token = this.getUserToken();
        token = token.replace('bearer ', '');
        return `${url}?token=${token}`;
    }


    public remove(key) {
        this.storageProvider.removeData(key);
    }


    private getUser() {
        let ob = this.getUserJson();
        if (!this.user && ob) {
            this.user = ob;
        }
        return this.user;
    }

    private getUserJson() {
        return this.storageProvider.get(Storage.USER_KEY);
    }


}
