import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
const FILTER_PAG_REGEX = /[^0-9]/g;

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

    @Input() table: any;
    @Input() headers: any;
    @Input() data: any;

    @Input() currentPage;
    @Input() pageSize;
    @Input() collectionSize;

    faSearch = faSearch;


    // tslint:disable-next-line:no-output-on-prefix
    @Output() onPageClick: EventEmitter<any> = new EventEmitter();
    term: any = '';
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onSearchKeyUp: EventEmitter<any> = new EventEmitter();
    @Input() canSearch = true;


    constructor() {
    }

    ngOnInit(): void {
    }
    pageClick(event: number) {
        this.onPageClick.emit(event);
    }

    onKeyUpSearch() {
        this.onSearchKeyUp.emit(this.term);
    }
    selectPage(page: string) {
        this.currentPage = parseInt(page, 10) || 1;
        this.onPageClick.emit(this.currentPage);
    }
    formatInput(input: HTMLInputElement) {
        input.value = input.value.replace(FILTER_PAG_REGEX, '');
    }
}
