import {CommonUtils} from '../services/utility/common-utils';

export class PaylinkInformation {
    id?: string;
    isCardSelected?: boolean;
    image?: any;
    grossTotal: string;
    extraTotal = 0;
    netTotal = 0;
    name: string;
    status?: string;
    receiver?: string;
    dueDate?: string;
    description: string;
    editable?: boolean;
    url?: string;
    templateItems?: PaylinkTemplateItem[];
    templateMetas?: TemplateMeta[];

    public isPaylinkEmpty?() {
        return CommonUtils.isEmpty(
            this.grossTotal,
            this.name,
            this.description);
    }

    public init?(paylinkInformation: PaylinkInformation){
        let reponse = new PaylinkInformation();
        reponse.id = paylinkInformation.id;
        reponse.image = paylinkInformation.image;
        reponse.name = paylinkInformation.name;
        reponse.grossTotal = paylinkInformation.grossTotal;
        reponse.description = paylinkInformation.description;
        reponse.templateMetas = paylinkInformation.templateMetas;
        return reponse;
    }

    public initPaylinkTemplateItem?(){
        this.templateItems = [];
        this.templateItems.push(new PaylinkTemplateItem(
            this.name,
            this.description,
            this.grossTotal,));
    }

}

export class PaylinkTemplateItem {
    id: string;
    name: string;
    description: string;
    unitPrice: string;


    constructor(name: string, description: string, unitPrice: string) {
        this.name = name;
        this.description = description;
        this.unitPrice = unitPrice;
    }
}

export class TemplateMeta {
    id: string;
    key: string;
    value: string;
    flag: string;
    type: string;
    currency: string;
    status: string;
}