import {Component, OnInit, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StateService} from '../../../services/state-service/state.service';

@Component({
    selector: 'app-paylink-list-card',
    templateUrl: './paylink-list-card.component.html',
    styleUrls: ['./paylink-list-card.component.scss']
})
export class PaylinkListCardComponent implements OnInit {

    @Input() paylink: any;

    constructor(
        private stateService: StateService,
        private modalService: NgbModal,
    ) {
    }

    ngOnInit(): void {
    }

    generatePayLink(content) {
        this.stateService.shareData_paylinkInformation = this.paylink;
        this.modalService.open(content, {centered: true, size: 'lg', windowClass: 'generate-link-modal-content-global'});
    }
}
