import {Const} from '../commons/const';

export class FormDataFile {
    key?: string;
    content: any = null;
    type = Const.TEXT;

    public isFormDataEmpty?() {
        return this.content === null;
    }


}