<div class="row w-100 fixed-top justify-content-center m-auto bg-danger" style="letter-spacing: 1px" *ngIf="showMaintenance">
    {{showMaintenance}}
</div>
<main class="page-content w-100" style="z-index: 4; " [class.mt-3]="showMaintenance">
    <nav class="navbar navbar-expand-md">
        <div class="no-gutters w-100">
            <table class="w-100">
                <tr>
                    <td>
                        <a class="navbar-brand">
                            <img class="img-responsive img-rounded" src="assets/logo.png">
                        </a>
                    </td>
                    <td class="text-end" *ngIf="shouldUserIconShow">
                        <div class="user-btn-container dropdown dropbtn">
                            <img src="https://www.wwe.com/f/styles/og_image/public/all/2019/10/RAW_06202016rf_1606--3d3997f53e6f3e9277cd5a67fbd8f31f.jpg"
                                style="width: 24px; height: 24px;" alt="" class="rounded-circle">
                            <div class="d-flex float-end ms-1">{{user.firstName + ' ' + user.lastName}}</div>
                            <div class="dropdown-content">
                                <a (click)="toProfile()">Profile</a>
                                <a (click)="logout()">Logout</a>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav">
            </ul>
        </div>

    </nav>
</main>
