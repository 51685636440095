/**
 * Created by imal365 on 7/18/18.
 */
import {Log} from '../../commons/log';

// import SimpleCrypto from 'simple-crypto-js/lib/SimpleCrypto';

declare var $;

export class CommonUtils {

    private static simpleScret = "asdasdqwdxsax";
    static fileTypes = {
        'application/pdf': {
            "type": "pdf"
        },
        'image/jpg': {
            "type": "jpg"
        },
        'image/x-png': {
            "type": "png"
        },
        'image/png': {
            "type": "png"
        },
        'image/jpeg': {
            "type": "jpeg"
        },
    };

    static checkName(name) {
        if (name == 'gateway_charge') {
            return 'Convenience fee';
        }
        if (name == 'CHARGEBACK') {
            return 'Charged Back';
        }
        if (name == 'VOID') {
            return 'Void';
        }
        if (name == 'REFUNDED') {
            return 'Refunded';
        }
        if (name == 'PAYMENT') {
            return 'Payment';
        }
        if (name == 'INVOICE') {
            return 'Invoice';
        }
        return name;
    }

    public static tempImageLoad(url: string, sucsss: () => void, error: () => void) {
        let tester = new Image();
        tester.onload = sucsss;
        tester.onerror = error;
        tester.src = url;
    }
    static capitalize(s) {
        // returns the first letter capitalized + the string from index 1 and out aka. the rest of the string
        return s? s[0].toUpperCase() + s.substr(1) : s;
    }
    public static randInt(length: number) {
        return Math.floor(
            Math.pow(10, length - 1)
            + Math.random()
            * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
    }

    public static randomString(length) {
        let result = '';
        let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = length; i > 0; --i) {
            result += chars[Math.floor(Math.random() * chars.length)];
        }
        return result;
    }

    public static cloneJsonParsableObject(jsonobject) {
        return JSON.parse(JSON.stringify(jsonobject));
    }


    public static formatAsCurrancy(amount) {
        return (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    public static openAppFromAppURI(uri) {
        setTimeout(() => {
            window.location = uri;
        }, 1000);
        location.href = uri;
        window.location.replace(uri);
    }

    public static getDateMap(date = new Date()) {
        let mon = date.getMonth() + 1;
        let da = date.getDate();
        let month = (`${mon}`.length == 1) ? ('0' + mon) : mon + '';
        let day = (`${da}`.length == 1) ? ('0' + da) : da + '';
        return {
            year: date.getFullYear(),
            month: month,
            day: day
        };
    }


    public static downloadCSV(filename, rows) {
        let processRow = function (row) {
            let finalVal = '';
            for (let j = 0; j < row.length; j++) {
                let innerValue = row[j] === null ? '' : row[j].toString();
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
                }
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0) {
                    result = '"' + result + '"';
                }
                if (j > 0) {
                    finalVal += ',';
                }
                finalVal += result;
            }
            return finalVal + '\n';
        };

        let csvFile = '';
        for (let i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        let blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            let link: any = document.createElement('a');
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    public static downloadUnicodeCSV(filename, datasource) {
        let content = '',
            newLine = '\r\n';

        for (let line of datasource) {
            let i = 0;
            for (let item of line) {
                let it = item.replace(/"/g, '""');
                if (it.search(/("|,|\n)/g) >= 0) {
                    it = '"' + it + '"';
                }
                content += (i > 0 ? ',' : '') + it;
                ++i;
            }
            content += newLine;
        }
        let link: any = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(content));
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    static isString(obj) {
        return (Object.prototype.toString.call(obj) === '[object String]');
    }

    static fromArray(array, func) {
        let narr = [];
        if (array == null) {
            array = [];
        }
        array.forEach((itm) => {
            let result = func(itm);
            if (result != null) {
                narr.push(result);
            }
        });
        return narr;
    }

    static notEmptyOrNull(str) {
        if (str) {
            return ('' + str).trim().length > 0;
        }
        return false;
    }

    static isEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    static isPhone(phone) {
        return phone && phone.match(/^([0-9-]){9,15}$/g);
    }


    // static encrypt(text) {
    //   let crypto = new SimpleCrypto(this.simpleScret);
    //   return window.btoa(crypto.encrypt(text));
    // }
    //
    // static decrypt(text) {
    //   let crypto = new SimpleCrypto(this.simpleScret);
    //   return crypto.decrypt(window.atob(text));
    // }

    static simpleEncode(text) {
        let tr = CommonUtils.utoa(text);
        let ch = tr.charAt(tr.length / 2);
        Log.info(ch + tr);
        return ch + tr;
    }

    static simpleDecode(text) {
        Log.info(text.substring(1, text.length));
        return CommonUtils.atou(text.substring(1, text.length));
    }

    private static atou(b64) {
        return decodeURIComponent(escape(atob(b64)));
    }

    private static utoa(data) {
        return btoa(unescape(encodeURIComponent(data)));
    }

    static setCharAt(str, index, chr) {
        if (index > str.length - 1) {
            return str;
        }
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    static dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    static removeWhiteSpaces(str): string {
        return str.replace(/\s/g, '');
    }

    static isEmpty(...args: string[]) {
        for (let count = 0; count < args.length; count++) {
            let value = args[count];
            if (value === "" || value === undefined) {
                return true;
            }
        }
        return false;
    }

    static getPriceCardDisplayValue(price: string): string {
        let priceLength = price.length;
        let cardRoundPrice = price.substr(0, priceLength - 2);
        let cardDecimalPoints = price.substr(priceLength - 2, priceLength);
        return cardRoundPrice + "." + cardDecimalPoints;
    }

    static copyToClipboard = (value: string) => {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', value);
            e.preventDefault();
        });
        document.execCommand('copy');
    };

    static isNullOrEmpty(value: any) {
        return value == null || value == '';
    }

    static isNotNullOrEmpty(value: any) {
        return !this.isNullOrEmpty(value);
    }

    public static checkFileTypes(type) {
        if (CommonUtils.fileTypes.hasOwnProperty(type)) {
            return CommonUtils.fileTypes[type]?.type;
        }
        return 'png';
    }

    public static intToString(num, fixed) {
        if (num === null) {
            return null;
        } // terminate early
        if (num === 0) {
            return '0';
        } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        let b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
        return e;
    }

    static countDecimals(value) {
        if (Math.floor(value) === value) {
            return 0;
        }
        return value.toString().split(".")[1].length || 0;
    }

    static lettersOnly(event) {
        let charCode = event.keyCode;

        if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8) {
            return true;
        } else {
            return false;
        }
    }

    static getType(type: any) {
        switch (type) {
            case 'PAY_LINK':
                return "Paylink";
            case 'INVOICE':
                return "Invoice";
            case 'ONE_TIME':
                return "One Time";

            default:
                return type;
        }
    }

    static getMonth(month) {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        return monthNames[month - 1];
    }
}
