<div class="modal-container">
    <button type="button" class="close position-absolute" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="content">
        <div class="form-group form-floating ms-2 me-2">
            <div class="row">
                <div class="col-md-5">
                    <div class="paylink-details-container">
                        <img src="{{ paylinkInformation.templateMetas[0].value }}" alt="" class="paylink-detail-image">
                        <div class="paylink-detail-info-container">
                            <label class="paylink-detail-info-title">Service Name</label>
                            <p class="paylink-detail-info">{{paylinkInformation.name}}</p>
                        </div>
                        <div class="paylink-detail-info-container">
                            <label class="paylink-detail-info-title">Service Name</label>
                            <p class="paylink-detail-info">{{displayPrice}}</p>
                        </div>
                        <div class="paylink-detail-info-container">
                            <label class="paylink-detail-info-title">Service Name</label>
                            <p class="paylink-detail-info">{{paylinkInformation.description}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="row receiver-details-container">
                        <div id="reciver-details">
                            <label>Receiver Information</label>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group form-floating ">
                                <input type="text" name="first-name" id="first-name" class="form-control input-primary"
                                       placeholder="First Name" [(ngModel)]="firstname">
                                <label class="placeholder-text">First Name</label>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group form-floating ">
                                <input type="text" name="last-name" id="last-name" class="form-control input-primary"
                                       placeholder="Last Name" [(ngModel)]="lastname">
                                <label class="placeholder-text">Last Name</label>
                            </div>
                        </div>


                        <div class="col-md-12">
                            <div class="form-group form-floating ">
                                <input type="text" name="email" id="email" class="form-control input-primary"
                                       placeholder="Email" [(ngModel)]="email.value">
                                <label class="placeholder-text" for="email">Email</label>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group form-floating  ">
                                <input type="text" name="phone" id="phone" class="form-control input-primary"
                                       placeholder="Phone"
                                       [(ngModel)]="contact.value">
                                <label class="placeholder-text" for="phone">Phone</label>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group form-floating">
                                <input type="date" name="due-date" id="due-date" class="form-control input-primary"
                                       placeholder="Due Date"
                                       [(ngModel)]="dueDate.value">
                                <label class="placeholder-text" for="due-date">Due Date</label>
                            </div>
                        </div>

                        <div class="col-md-12 mt-3">
                            <button type="button" class="btn btn-light create-link-btn" (click)="generateLink()">Create
                                Link
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>

</div>

<div class="link-generate-container mt-2" *ngIf="isUrlCreated">
    <div class="row">
        <div class="col-12 get-link-text">
            <img src="assets/link.png" alt="" class="get-link-image">
            <span>Get Link</span>
        </div>
        <div class="col-12">
            <p>You can share following link with your customer.</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="input-group paylink-link" (click)="copyPaylinkToClipBoard()">
                <input type="text" class="form-control" value="{{ paymentUrl }}" readonly>
                <span class="input-group-text" id="basic-addon2"  title="Click here to copy"> <img src="assets/copy.png" alt=""></span>
            </div>
            <span class="link-copied" *ngIf="isPaymentLinkCopied">Payment link copied</span>
        </div>
    </div>
</div>